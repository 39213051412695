

.modalBackground{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.modalContent{
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    width: 50%;
    min-width: 400px;
    max-width: 700px;
    border-radius: 0.25rem;
    top: 0rem;
    position: fixed;
    left: 50%;
    transform: translate(-50%,5%);
}
.modalContent h1 {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 0.5rem;
}
.modalContent h1 i {
    margin: auto;
    float: right;
}
