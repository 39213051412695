
.task {
    margin-bottom: 1rem;
}
.task dt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor:default;
    padding: 1rem;
    background: #f3f3f3;
    font-size: 110%;
}
.description-simple{
    text-align: start;
    white-space: pre-wrap;
}
.task-error {
    background: #df777b;
    font-size: 110%;
    display: flex;
    padding-left: 1rem;
}
.task.completed dt {
    background-color: #7abd89;
}
.task.canceled dt {
    background-color: #df777b;
}

.task dd {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    padding: 0.4rem;
    background: #f3f3f3;
    font-size: 90%;
}
