
.service {
    margin-bottom: 1rem;
}
.service li dt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: #f3f3f3;
    font-size: 110%;
    margin-left: 1rem;
}
.service li dd {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    padding-left: 1rem;
    padding-bottom: 1rem;
    background: #f3f3f3;
    margin-left: 1rem;
}
.email__pass {

}

.email__pass {
    display: grid;
    grid-template-columns: auto min-content;
    grid-column-gap: 0.4rem;
}

.email__pass i {
    margin: auto 0 0 0;
}

.email__pass label {
    grid-column: span 2;
    font-size: 110%;
}

.email {
    grid-column: span 2;
}