

.changeCustomerLabel{
    width: 70%;
    margin: 0;
    padding: 0rem;
    font-size: 12px;
}
.changeCustomerLabel input{
    max-height: 1.5rem;
}
