.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



a {
  color: #0366d6;
}



code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.button {
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: #638b6c;
  border-color: #4f855b;
  color: #fff;
}
.button:hover {
  background-color: #3d5442;
}


.label-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 110%;
}
.label-checkbox-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 110%;
  padding-top: 1rem;
  margin:auto;
}
.check_box
{
  margin:initial;
}


.button {
  margin: 16px;
  align-content: center;

  border-radius: 10px;
  padding: 7px 25px 6px 25px;
  border: 2px solid #4f855b;
  border-radius: 1000px;
  display: inline-block;
  vertical-align: middle;
  background-color: #638b6c;
  font-size: 12px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}

.link {
  align-items: center;
  cursor: pointer;
  text-decoration-line:underline;
  color: deepskyblue;
}
.container {
  padding-right: 0;
  padding-left: 0;
}

@media screen {
  html {
    background-image: url("../public/background.jpg");
  }

}

i {
  z-index: 0;
  margin-left: 2rem;
  margin-right: 2rem;
  align-items: end;
  cursor: pointer;
}
