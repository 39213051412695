a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;

}

.html {
    font-size: 16px;
}
@media screen and (min-width: 1000px) {
    html {
        font-size: 16px;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
        background-size: cover;
    }
    html body {
        background-color: transparent;
    }

}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }


.navbar-nav__button {
    display: none;
}

@media screen and (max-width: 1000px) {
    html {
        font-size: 16px;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
        background-size: cover;
    }
    html body {
        background-color: transparent;
    }
    .navbar-nav__custom {
        display: none !important;
    }
    .navbar-nav__button {
        display: block;
    }
    .navbar-nav__button .material-icons {
        margin: auto;
    }
    a.navbar-brand {
        font-size: 12px;
    }
}



.styledMenu {
    top: 0;
    width: 35vw;
    position: fixed;
    background-color: #F0F0F0;
    z-index: 2;
    padding: 1rem 0;
    flex-direction: column;
    transform: translateX(+150%)
}

.styledMenu.open {
    transform: translateX(+150%);
    animation: ani 1s forwards;
    display: flex;

    right: 0;
}

@keyframes ani {
    0% {
        transform: translateX(+150%);
    }

    100% {
        transform: translateY(0);
    }
}

.styledLink {
    padding-left: 2rem;
    color: #343a40;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
}

.logo_image {
    width: 60px;
    height: 60px;
    border-radius: 30%;
    overflow: hidden;
    margin-right: 1rem;
}
.container-nav {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
 }
