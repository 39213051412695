

.file {
    margin-bottom: 1rem;
}
.file label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    padding: 1rem;
    background: #f3f3f3;
    font-size: 110%;
}

.file i {
    z-index: 1;
    margin-left: 2rem;
}